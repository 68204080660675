import * as React from "react";

import Layout from "../components/layout";
import { graphql } from "gatsby";

const EbookDownload = ({ location }) => {
  return (
    <Layout
      noIndex
      location={location}
      seoTitle="Pobierz Ebook"
      seoDescription="Ebook: Jak kontrolować stan i wyposażenie przy flocie powyżej 10 pojazdów"
    >
      <div className="container">
        <section className="ins-page-section text-center">
          <h1 className="error-code mb-5">Dziękujemy</h1>
          <a
            href="/files/eBook-Jak-kontrolowac-stan-i-wyposazenie-przy-flocie-powyzej-10-pojazdow.pdf"
            target="_blank"
            className="btn btn-primary btn-error-action"
          >
            Pobierz Ebook
          </a>
        </section>
      </div>
    </Layout>
  );
};

export default EbookDownload;

export const query = graphql`
  query ($language: String!) {
    site {
      buildTime(formatString: "YYYY-MM-DD hh:mm a z")
    }
    locales: allLocale(
      filter: { ns: { in: ["index"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
